@import url("https://unpkg.com/@tailwindcss/typography@0.2.x/dist/typography.min.css");
@import url("https://unpkg.com/tailwindcss@^1.0/dist/tailwind.min.css");
@import url('https://fonts.googleapis.com/css2?family=Comfortaa&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ramaraja&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

html {
  font-family: 'Comfortaa', cursive;
  color: #141823;
}

.title-font {
  font-family: 'Ramaraja', sans-serif;
}

.prose img {
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
}

.home-name {
  font-size: 150px;
}

.text-gold {
  color: #FFAD1C;
}
.background-gold {
  background-color: #FFAD1C;
  color: white
}

.text-blue {
  color: #00196E
}

.btn-blue {
  background-color: #00196E;
  color: white;
}

.btn-blue:hover {
  background-color: #FFAD1C;
  color: white;
}

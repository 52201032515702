.container-iframe-16-9 {
  position: relative;
  overflow: hidden;
  width: 60%;
  padding-top: 33.75%; /* 16:9 Aspect Ratio */
}

.container-iframe-4-3 {
  position: relative;
  overflow: hidden;
  width: 60%;
  padding-top: 45%; /* 4:3 Aspect Ratio */
}

.container-iframe-3-2 {
  position: relative;
  overflow: hidden;
  width: 60%;
  padding-top: 40%; /* 3:2 Aspect Ratio */
}

.container-iframe-audio {
  position: relative;
  overflow: hidden;
  width: 60%;
  padding-top: 10%; /* Audio bar Aspect Ratio */
}
@media only screen and (max-width: 900px) {
  .container-iframe-audio {
    padding-top: 15%; /* Audio bar Aspect Ratio */
  }
}

@media only screen and (max-width: 640px) {
  .container-iframe-audio {
    padding-top: 20%; /* Audio bar Aspect Ratio */
  }
}

@media only screen and (max-width: 480px) {
  .container-iframe-audio {
    padding-top: 25%; /* Audio bar Aspect Ratio */
    width:100%
  }
}

@media only screen and (max-width: 360px) {
  .container-iframe-audio {
    padding-top: 35%; /* Audio bar Aspect Ratio */
    width:100%
  }
}

.container-iframe-no {
  position: relative;
  overflow: hidden;
  width: 60%;
}
  
/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}
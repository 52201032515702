@import url(https://unpkg.com/@tailwindcss/typography@0.2.x/dist/typography.min.css);
@import url(https://unpkg.com/tailwindcss@^1.0/dist/tailwind.min.css);
@import url(https://fonts.googleapis.com/css2?family=Comfortaa&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ramaraja&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
html {
  font-family: 'Comfortaa', cursive;
  color: #141823;
}

.title-font {
  font-family: 'Ramaraja', sans-serif;
}

.prose img {
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
}

.home-name {
  font-size: 150px;
}

.text-gold {
  color: #FFAD1C;
}
.background-gold {
  background-color: #FFAD1C;
  color: white
}

.text-blue {
  color: #00196E
}

.btn-blue {
  background-color: #00196E;
  color: white;
}

.btn-blue:hover {
  background-color: #FFAD1C;
  color: white;
}



.h-half {
    position: absolute;
    top: 50%;
    transform: translate(25%,-50%);
    height: 70%;
}

.vertical-centre {
    position: relative;
    top: 10%;
    bottom: 10%;
  }

/*mobile h-half*/
@media only screen and (max-width: 640px) {
    .h-half {
        position: absolute;
        transform: translate(0%,-30%);
        height: 50%;
    }
}
.pic-size {
    width: 100%;
}

.sliderWrapper {
    padding:36px 18px 0px 0px;
}
.sliderWrapper .slider {
    margin-bottom:10px;
    position:relative;
    text-align:left;
}
.sliderWrapper .slider .ui-slider-handle {
    background-color:#00196E;
    border-radius:50%;
    cursor:default;
    position:absolute;
    width:28px;
    color: white;
    text-align: center;
    vertical-align: sub;
    font-size: 1rem;
}

.selected-handle {
    color: red !important; 
}

.sliderWrapper .slider .ui-slider-range {
    position:absolute;
    font-size:.7em;
    display:block;
    border:0;
    background:#FFAD1C 0 0
}
.sliderWrapper .slider.ui-slider-horizontal {
    background-color:#6d6d6d;
    border-radius:5px;
    height:8px;
}
.sliderWrapper .slider.ui-slider-horizontal .ui-slider-handle {
    top:-10px;
    margin-left:-6px;
}
.sliderWrapper .slider.ui-slider-horizontal .ui-slider-handle:hover {
    cursor:pointer
}
.sliderWrapper .slider.ui-slider-horizontal .ui-slider-range {
    top:0;height:100%
}
.sliderWrapper .slider.ui-slider-horizontal .ui-slider-range-min {
    left:0
}
.sliderWrapper .slider.ui-slider-horizontal .ui-slider-range-max {
    right:0
}
.sliderWrapper .legend label {
    display:inline-block;
    font-size:18px;
    text-align:center;
    padding-left: 10px;
}
.sliderWrapper .legend label:first-child {
    left:-12px;
    position:relative;
    text-align:left;
}
.sliderWrapper .legend label:last-child {
    position:relative;
    right:-18px;
    text-align:right
}
.height-80vh {
    height: 60vh;
}
.container-iframe-16-9 {
  position: relative;
  overflow: hidden;
  width: 60%;
  padding-top: 33.75%; /* 16:9 Aspect Ratio */
}

.container-iframe-4-3 {
  position: relative;
  overflow: hidden;
  width: 60%;
  padding-top: 45%; /* 4:3 Aspect Ratio */
}

.container-iframe-3-2 {
  position: relative;
  overflow: hidden;
  width: 60%;
  padding-top: 40%; /* 3:2 Aspect Ratio */
}

.container-iframe-audio {
  position: relative;
  overflow: hidden;
  width: 60%;
  padding-top: 10%; /* Audio bar Aspect Ratio */
}
@media only screen and (max-width: 900px) {
  .container-iframe-audio {
    padding-top: 15%; /* Audio bar Aspect Ratio */
  }
}

@media only screen and (max-width: 640px) {
  .container-iframe-audio {
    padding-top: 20%; /* Audio bar Aspect Ratio */
  }
}

@media only screen and (max-width: 480px) {
  .container-iframe-audio {
    padding-top: 25%; /* Audio bar Aspect Ratio */
    width:100%
  }
}

@media only screen and (max-width: 360px) {
  .container-iframe-audio {
    padding-top: 35%; /* Audio bar Aspect Ratio */
    width:100%
  }
}

.container-iframe-no {
  position: relative;
  overflow: hidden;
  width: 60%;
}
  
/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}
